import { Card, CardBody } from "@nextui-org/react";
import { Image } from "@nextui-org/react";
import { GoogleLogin } from "@react-oauth/google";
import { type ActionFunctionArgs, json } from "@remix-run/cloudflare";
import { useSubmit } from "@remix-run/react";
import { authenticator } from "~/services/auth.server";

export const action = async ({ request }: ActionFunctionArgs) => {
  await authenticator.authenticate("google", request, { successRedirect: "/" });
  return json({});
};

export default function Login() {
  const submit = useSubmit();
  const handleLogin = ({ credential }: { credential?: string }) => {
    if (!credential) return;

    const formData = new FormData();
    formData.append("credential", credential);
    submit(formData, { method: "POST", action: "/login" });
  };

  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center bg-slate-100 gap-4">
      <div className="flex flex-col items-center justify-center text-2xl font-medium">
        <Image src="/favicon-cytometa.ico" />
        <p>Cytometa</p>
      </div>
      <Card className="px-8">
        <CardBody className="px-auto h-32 justify-center">
          <div className="flex w-full items-center justify-center">
            <GoogleLogin
              onSuccess={({ credential }) => handleLogin({ credential })}
              onError={() => {}}
              text="signin_with"
              shape="square"
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
